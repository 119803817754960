const GA_COOKIE_KEY = "gdpr-google-analytics"
const FB_PIXEL_COOKIE_KEY = "gdpr-facebook-pixel"
const ADULT_KEY = "is_adult"
const REDIRECT_URL = "redirect_url"
const GOOGLE_API_KEY = "AIzaSyCHeuUPYtt_a94HLFrIg8k2oQEK-HT8CUg"

module.exports = {
  GA_COOKIE_KEY,
  FB_PIXEL_COOKIE_KEY,
  ADULT_KEY,
  REDIRECT_URL,
  GOOGLE_API_KEY
}
